import React, { useState, useEffect } from "react";

import SignIn from "./SignIn";
import TimelinePage from "./TimelinePage";
import FaqPage from "./FaqPage";
import ChatPage from "./ChatPage";
import UserPage from "./UserPage";
import Cookies from "js-cookie";
import NavBar from "./components/NavBar";

const TIMELINE_PAGE = "timeline";
const FAQ_PAGE = "faq";
const CHAT_PAGE = "chat";
const USER_PAGE = "user";

function App() {
  const [isLoggedIn, setLoggedIn] = useState(false);

  const [currentPage, setCurrentPage] = useState(TIMELINE_PAGE);

  const logInUser = () => {
    setLoggedIn(true);
  };

  const updatePage = (page) => {
    setCurrentPage(page);
  };

  useEffect(() => {
    const isLoggedInCookie = Cookies.get("isLoggedIn");

    setLoggedIn(isLoggedInCookie === "true");
  }, []);

  return (
    <div>
      {isLoggedIn ? (
        <div>
          <NavBar currentPage={currentPage} updatePage={updatePage} />
          <div className="container">
            {currentPage == TIMELINE_PAGE ? (
              <TimelinePage />
            ) : currentPage == FAQ_PAGE ? (
              <FaqPage />
            ) : currentPage == CHAT_PAGE ? (
              <ChatPage />
            ) : (
              <UserPage />
            )}
          </div>
        </div>
      ) : (
        <div className="login-page-container">
          <SignIn logInUser={logInUser} />
        </div>
      )}
    </div>
  );
}
export default App;

import React, { useState, useEffect } from "react";
import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  orderBy,
} from "firebase/firestore";
import Cookies from "js-cookie";

const firebaseConfig = {
  apiKey: "AIzaSyAU7lZgtBz3nSyN6tbLDODamxLNQj_g82E",
  authDomain: "shishenxue.firebaseapp.com",
  projectId: "shishenxue",
  storageBucket: "shishenxue.appspot.com",
  messagingSenderId: "240879603248",
  appId: "1:240879603248:web:e3df398ecc7c82494f27f5",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const MALE_AVATAR =
  "https://firebasestorage.googleapis.com/v0/b/shishenxue.appspot.com/o/male_avatar.jpg?alt=media&token=d3b79404-cf09-4570-98cd-a279dc65329b";
const FEMALE_AVATAR =
  "https://firebasestorage.googleapis.com/v0/b/shishenxue.appspot.com/o/female_avatar.JPG?alt=media&token=e3705a0a-ddef-4352-beba-0da839e14c41";

function ChatPage() {
  const [chatData, setChatData] = useState([]);
  const [editMode, setEditMode] = useState(false);

  const [message, setMessage] = useState("");

  const handleInputChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (message == "") {
      console.log("Message cannot be empty");
      return;
    }

    addChatToFirestore(message);
    disableOverlay();
  };

  const disableOverlay = () => {
    setMessage("");
    setEditMode(false);
  };

  const fetchData = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, "chat"));
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      const sortedData = data.sort((a, b) => b.date - a.date);
      setChatData(sortedData);
    } catch (error) {
      console.error("Error getting documents: ", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleEditMode = () => {
    setEditMode(true);
  };

  const addChatToFirestore = async (message) => {
    try {
      const db = getFirestore();
      const chatCollectionRef = collection(db, "chat");
      const currentUser = Cookies.get("username");

      const chatObj = {
        avatar: currentUser === "shishenxue" ? FEMALE_AVATAR : MALE_AVATAR,
        date: serverTimestamp(),
        message: message,
      };

      await addDoc(chatCollectionRef, chatObj);
      console.log("Chat data added to Firestore successfully");

      fetchData();
    } catch (error) {
      console.error("Error adding chat data to Firestore:", error);
    }
  };

  return (
    <div className="chat">
      <div className="landing-time">
        <p className="landing-time-day">Message</p>
        <p className="landing-time-detail">留点什么吧</p>
        <i className="fa-regular fa-pen-to-square" onClick={handleEditMode}></i>
      </div>

      <div className="chat-list-container">
        {chatData.map((chat) => (
          <div className="chat-item" key={chat.id}>
            <img className="chat-item-avatar" src={chat.avatar} />
            <div class="imessage">
              <p className="from-them">
                {chat.message}
                <br />
                <span className="from-them-date">
                  {new Date(
                    chat.date.seconds * 1000 + chat.date.nanoseconds / 1e6
                  ).toLocaleString()}
                </span>
              </p>
            </div>
          </div>
        ))}
      </div>

      {editMode ? (
        <div className="chat-overlay" onClick={disableOverlay}></div>
      ) : null}

      {editMode ? (
        <div className="chat-edit-container">
          <form onSubmit={handleSubmit}>
            <textarea
              value={message}
              onChange={handleInputChange}
              placeholder="写点什么呢......"
            />
            <div className="chat-edit-button-container">
              <button type="submit">发送！</button>
            </div>
          </form>
        </div>
      ) : null}
    </div>
  );
}

export default ChatPage;

import React from "react";

const TimelineItem = (props) => {
  return (
    <div className="timeline-item">
      <div className="timeline-item-date">
        <img className="timeline-item-date-icon" src="/images/calendar.png" />
        <p>{props.date}</p>
      </div>

      <p className="timeline-item-content">{props.content}</p>

      <div class="timeline-item-tag">
        {props.tag.map((item, index) => (
          <p key={index} className="timeline-item-tag-item">
            {item}
          </p>
        ))}
      </div>

      <p className="timeline-item-line"></p>
    </div>
  );
};

export default TimelineItem;

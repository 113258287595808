import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NavBar = (props) => {
  return (
    <div className="navbar">
      <i
        className="fa-regular fa-calendar"
        style={{ color: props.currentPage == "timeline" ? "#ff8282" : "gray" }}
        onClick={() => props.updatePage("timeline")}
      ></i>
      <i
        className="fa-solid fa-clipboard-check"
        style={{ color: props.currentPage == "faq" ? "#ff8282" : "gray" }}
        onClick={() => props.updatePage("faq")}
      ></i>
      <i
        className="fa-regular fa-message"
        style={{ color: props.currentPage == "chat" ? "#ff8282" : "gray" }}
        onClick={() => props.updatePage("chat")}
      ></i>
      <i
        className="fa-regular fa-circle-user"
        style={{ color: props.currentPage == "user" ? "#ff8282" : "gray" }}
        onClick={() => props.updatePage("user")}
      ></i>
    </div>
  );
};

export default NavBar;

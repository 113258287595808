import React, { useState, useEffect } from "react";
import TimelineItem from "./components/TimelineItem";

const FIRST_DAY_MET = new Date("2023-09-10");
const targetDateTime = new Date("2023-09-10T14:00:00");

function TimelinePage() {
  const [timelineData, setTimelineData] = useState([]);
  const [daysSince, setDaysSince] = useState(0);
  const [timePassed, setTimePassed] = useState({
    days: 0,
    hours: 0,
    minutes: 0,
    seconds: 0,
  });

  // Function to calculate the number of days since a specific date
  const calculateDaysSince = (targetDate) => {
    const currentDate = new Date();
    const targetDateTime = targetDate.getTime();

    const timeDifference = Math.abs(targetDateTime - currentDate);
    const daysDifference = Math.floor(timeDifference / (1000 * 3600 * 24));

    setDaysSince(daysDifference);
  };

  // Function to calculate the time passed since a specific date and time
  const calculateTimePassed = () => {
    const currentDate = new Date();
    const timeDifference = Math.abs(targetDateTime - currentDate);

    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    setTimePassed({
      days: days,
      hours: hours % 24,
      minutes: minutes % 60,
      seconds: seconds % 60,
    });
  };

  useEffect(() => {
    // Calculate and set the number of days since the target date
    calculateDaysSince(FIRST_DAY_MET);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await import("./data/timeline.json");
        setTimelineData(data.default);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    calculateTimePassed();

    const intervalId = setInterval(() => {
      calculateTimePassed();
    }, 1000);

    return () => clearInterval(intervalId);
  }, [targetDateTime]);

  return (
    <div className="landing">
      <div className="landing-time">
        <p className="landing-time-day">第 {daysSince} 天</p>
        <p className="landing-time-detail">
          {timePassed.hours}小时 {timePassed.minutes}分钟, {timePassed.seconds}
          秒
        </p>
      </div>

      <div className="landing-avatar">
        <img
          className="avatar"
          src="https://firebasestorage.googleapis.com/v0/b/shishenxue.appspot.com/o/male_avatar.jpg?alt=media&token=d3b79404-cf09-4570-98cd-a279dc65329b"
        />
        <img className="love-heart" src="/images/love-1.gif" />
        <img
          className="avatar"
          src="https://firebasestorage.googleapis.com/v0/b/shishenxue.appspot.com/o/female_avatar.JPG?alt=media&token=e3705a0a-ddef-4352-beba-0da839e14c41"
        />
      </div>

      <div className="timeline">
        {timelineData.map((item) => (
          <TimelineItem
            key={item.date}
            date={item.date}
            content={item.content}
            tag={item.tag}
          />
        ))}
      </div>
    </div>
  );
}

export default TimelinePage;

import React, { useState, useEffect } from "react";

function FaqPage() {
  const [faqData, setFaqData] = useState([]);
  const [randomIndex, setRandomIndex] = useState(0);
  const [showHint, setShowHint] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await import("./data/faq.json");
        setFaqData(data.default);
        setRandomIndex(Math.floor(Math.random() * data.default.length));
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const handleRandomize = () => {
    const currentRandomIndex = randomIndex;
    let newIndex;

    // Generate a new random index until it's different from the current one
    do {
      newIndex = Math.floor(Math.random() * faqData.length);
    } while (newIndex === currentRandomIndex);

    setRandomIndex(newIndex);
    hideHint();
  };

  const displayHint = () => {
    setShowHint(true);
  };

  const hideHint = () => {
    setShowHint(false);
  };

  const toggleHint = () => {
    setShowHint(!showHint);
  };

  return (
    <div className="faq">
      <div className="landing-time">
        <p className="landing-time-day">FAQ</p>
        <p className="landing-time-detail">他和她的小日常</p>
      </div>

      <div class="card">
        <div class="content">
          <div class="front">
            {faqData.length > 0 && (
              <div>
                <p className="faq-item-question">
                  Q: {faqData[randomIndex].question}
                </p>

                {faqData[randomIndex].options.map((elem) => (
                  <p className="faq-item-option" key={elem}>
                    {elem}
                  </p>
                ))}
              </div>
            )}
          </div>
          <div class="back">
            {faqData.length > 0 && (
              <div>
                <p className="faq-item-answer">{faqData[randomIndex].answer}</p>
              </div>
            )}
          </div>
        </div>
      </div>

      <div className="faq-button-container">
        <button type="button" onClick={toggleHint}>
          提示
        </button>
        <button type="button" onClick={handleRandomize}>
          下一个
        </button>
      </div>

      {showHint ? (
        <div className="faq-hint-container">
          <p>{faqData[randomIndex].hint}</p>
        </div>
      ) : null}
    </div>
  );
}

export default FaqPage;

import React, { useState, useEffect } from "react";

function UserPage() {
  return (
    <div className="chat">
      <div className="landing-time">
        <p className="landing-time-day">Setting</p>
        <p className="landing-time-detail">用户信息</p>
      </div>
    </div>
  );
}

export default UserPage;

import React, { useState } from "react";
import Cookies from "js-cookie";

function SignIn(props) {
  const [formData, setFormData] = useState({
    username: "",
    password: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // TODO: verify user credentials from firestore
    if (formData.username == "shishenxue" && formData.password == "19940827") {
      Cookies.set("isLoggedIn", "true");
      Cookies.set("username", "shishenxue");
      props.logInUser();
    } else if (
      formData.username == "liyang" &&
      formData.password == "19960525"
    ) {
      Cookies.set("isLoggedIn", "true");
      Cookies.set("username", "liyang");
      props.logInUser();
    }

    setFormData({
      username: "",
      password: "",
    });
  };

  return (
    <div className="login-page">
      <h2>Login</h2>
      <form onSubmit={handleSubmit}>
        <label>
          <p>Username</p>
          <input
            type="text"
            name="username"
            value={formData.username}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <label>
          <p>Password</p>
          <input
            type="password"
            name="password"
            value={formData.password}
            onChange={handleChange}
            required
          />
        </label>
        <br />
        <button type="submit">Sign In</button>
      </form>
    </div>
  );
}

export default SignIn;
